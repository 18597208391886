<template>
  <CFooter :fixed="false">
    <div>
      <a href="https://mvp-apps.com" target="_blank">MVP-APPS</a>
      <span class="ml-1">&copy; {{ new Date().getFullYear() }} </span>
    </div>
  </CFooter>
</template>

<script>
export default {
  name: "TheFooter",
};
</script>
