export default [
  {
    _name: 'CSidebarNav',
    _children: [
      {
        _name: 'CSidebarNavItem',
        name: 'Dashboard',
        to: '/dashboard',
        icon: 'cil-speedometer',
        badge: {
          color: 'primary',
          text: 'NEW'
        }
      },

      {
        _name: 'CSidebarNavDropdown',
        name: 'Users',
        route: '/users',
        icon: 'cil-user',
        items: [
          {
            name: 'View user',
            to: '/users/view'
          },
          {
            name: 'Add user',
            to: '/users/add'
          }
        ]
      },
      {
        _name: 'CSidebarNavDropdown',
        name: 'Meeting',
        route: '/meeting',
        icon: 'cil-settings',
        items: [
          {
            name: 'View All',
            to: '/meeting/view'
          }
        ]
      },
      {
        _name: 'CSidebarNavDropdown',
        name: 'Activities',
        route: '/activities',
        icon: 'cil-settings',
        items: [
          {
            name: 'View',
            to: '/activities/view'
          }
        ]
      },
      {
        _name: 'CSidebarNavDropdown',
        name: 'Tickets',
        route: '/ticket',
        icon: 'cil-settings',
        items: [
          {
            name: 'View',
            to: '/ticket/view'
          }
        ]
      },
      {
        _name: 'CSidebarNavDropdown',
        name: 'Pages',
        route: '/pages',
        icon: 'cil-star',
        items: [
          {
            name: 'Login',
            to: '/pages/login'
          },
          {
            name: 'Register',
            to: '/pages/register'
          },
          {
            name: 'Error 404',
            to: '/pages/404'
          },
          {
            name: 'Error 500',
            to: '/pages/500'
          }
        ]
      }
    ]
  }
]